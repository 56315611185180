	/*
  	Flaticon icon font: Flaticon
  	Creation date: 12/09/2016 08:39
  	*/

@font-face {
  font-family: "flaticon-creative";
  src: url("fonts/creative.ttf") format("truetype"),
       url("fonts/creative.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "flaticon-creative";
    src: url("fonts/creative.svg#Flaticon") format("svg");
  }
}

[class^="fi-creative-"]:before, [class*=" fi-creative-"]:before,
[class^="fi-creative-"]:after, [class*=" fi-creative-"]:after {
  font-family: 'flaticon-creative';
  font-size: 20px;
  font-style: normal;
  margin-left: 20px;
}

.fi-creative-alarm:before { content: "\f100"; }
.fi-creative-arroba:before { content: "\f101"; }
.fi-creative-bar-chart:before { content: "\f102"; }
.fi-creative-basketball:before { content: "\f103"; }
.fi-creative-book:before { content: "\f104"; }
.fi-creative-bug:before { content: "\f105"; }
.fi-creative-cancel:before { content: "\f106"; }
.fi-creative-checked:before { content: "\f107"; }
.fi-creative-checked-1:before { content: "\f108"; }
.fi-creative-circular:before { content: "\f109"; }
.fi-creative-clipboard:before { content: "\f10a"; }
.fi-creative-clipboard-1:before { content: "\f10b"; }
.fi-creative-clock:before { content: "\f10c"; }
.fi-creative-cloud:before { content: "\f10d"; }
.fi-creative-cloud-computing:before { content: "\f10e"; }
.fi-creative-cloud-computing-1:before { content: "\f10f"; }
.fi-creative-cloud-computing-2:before { content: "\f110"; }
.fi-creative-cloud-computing-3:before { content: "\f111"; }
.fi-creative-computer:before { content: "\f112"; }
.fi-creative-computer-1:before { content: "\f113"; }
.fi-creative-credit-card:before { content: "\f114"; }
.fi-creative-cursor:before { content: "\f115"; }
.fi-creative-devices:before { content: "\f116"; }
.fi-creative-dial:before { content: "\f117"; }
.fi-creative-dial-1:before { content: "\f118"; }
.fi-creative-download:before { content: "\f119"; }
.fi-creative-download-1:before { content: "\f11a"; }
.fi-creative-download-2:before { content: "\f11b"; }
.fi-creative-earth-globe:before { content: "\f11c"; }
.fi-creative-edit:before { content: "\f11d"; }
.fi-creative-edit-1:before { content: "\f11e"; }
.fi-creative-email:before { content: "\f11f"; }
.fi-creative-email-1:before { content: "\f120"; }
.fi-creative-eye:before { content: "\f121"; }
.fi-creative-flag:before { content: "\f122"; }
.fi-creative-folder:before { content: "\f123"; }
.fi-creative-glasses:before { content: "\f124"; }
.fi-creative-head:before { content: "\f125"; }
.fi-creative-head-1:before { content: "\f126"; }
.fi-creative-headphones:before { content: "\f127"; }
.fi-creative-heart:before { content: "\f128"; }
.fi-creative-hide:before { content: "\f129"; }
.fi-creative-infinity:before { content: "\f12a"; }
.fi-creative-laptop:before { content: "\f12b"; }
.fi-creative-levels:before { content: "\f12c"; }
.fi-creative-levels-1:before { content: "\f12d"; }
.fi-creative-light-bulb:before { content: "\f12e"; }
.fi-creative-like:before { content: "\f12f"; }
.fi-creative-list:before { content: "\f130"; }
.fi-creative-login:before { content: "\f131"; }
.fi-creative-login-1:before { content: "\f132"; }
.fi-creative-logout:before { content: "\f133"; }
.fi-creative-logout-1:before { content: "\f134"; }
.fi-creative-mail:before { content: "\f135"; }
.fi-creative-mail-1:before { content: "\f136"; }
.fi-creative-megaphone:before { content: "\f137"; }
.fi-creative-microphone:before { content: "\f138"; }
.fi-creative-mouse:before { content: "\f139"; }
.fi-creative-mouse-1:before { content: "\f13a"; }
.fi-creative-next:before { content: "\f13b"; }
.fi-creative-next-1:before { content: "\f13c"; }
.fi-creative-next-2:before { content: "\f13d"; }
.fi-creative-next-3:before { content: "\f13e"; }
.fi-creative-padlock:before { content: "\f13f"; }
.fi-creative-padlock-1:before { content: "\f140"; }
.fi-creative-paper-plane:before { content: "\f141"; }
.fi-creative-paper-plane-1:before { content: "\f142"; }
.fi-creative-pencil:before { content: "\f143"; }
.fi-creative-pencil-1:before { content: "\f144"; }
.fi-creative-phone-call:before { content: "\f145"; }
.fi-creative-photo-camera:before { content: "\f146"; }
.fi-creative-photo-camera-1:before { content: "\f147"; }
.fi-creative-picture:before { content: "\f148"; }
.fi-creative-pie-chart:before { content: "\f149"; }
.fi-creative-piggy-bank:before { content: "\f14a"; }
.fi-creative-placeholder:before { content: "\f14b"; }
.fi-creative-plug:before { content: "\f14c"; }
.fi-creative-plug-1:before { content: "\f14d"; }
.fi-creative-plug-2:before { content: "\f14e"; }
.fi-creative-power-button:before { content: "\f14f"; }
.fi-creative-printer:before { content: "\f150"; }
.fi-creative-reload:before { content: "\f151"; }
.fi-creative-repeat:before { content: "\f152"; }
.fi-creative-repeat-1:before { content: "\f153"; }
.fi-creative-repeat-2:before { content: "\f154"; }
.fi-creative-reply:before { content: "\f155"; }
.fi-creative-screen:before { content: "\f156"; }
.fi-creative-screen-1:before { content: "\f157"; }
.fi-creative-search:before { content: "\f158"; }
.fi-creative-server:before { content: "\f159"; }
.fi-creative-settings:before { content: "\f15a"; }
.fi-creative-settings-1:before { content: "\f15b"; }
.fi-creative-settings-2:before { content: "\f15c"; }
.fi-creative-share:before { content: "\f15d"; }
.fi-creative-shopping-bag:before { content: "\f15e"; }
.fi-creative-shuffle:before { content: "\f15f"; }
.fi-creative-shuffle-1:before { content: "\f160"; }
.fi-creative-shuffle-2:before { content: "\f161"; }
.fi-creative-sitemap:before { content: "\f162"; }
.fi-creative-sledge:before { content: "\f163"; }
.fi-creative-speaker:before { content: "\f164"; }
.fi-creative-speaker-1:before { content: "\f165"; }
.fi-creative-speech-bubble:before { content: "\f166"; }
.fi-creative-speech-bubble-1:before { content: "\f167"; }
.fi-creative-speech-bubble-2:before { content: "\f168"; }
.fi-creative-speech-bubble-3:before { content: "\f169"; }
.fi-creative-speech-bubble-4:before { content: "\f16a"; }
.fi-creative-speech-bubble-5:before { content: "\f16b"; }
.fi-creative-switch:before { content: "\f16c"; }
.fi-creative-switch-1:before { content: "\f16d"; }
.fi-creative-tag:before { content: "\f16e"; }
.fi-creative-target:before { content: "\f16f"; }
.fi-creative-telephone:before { content: "\f170"; }
.fi-creative-transfer:before { content: "\f171"; }
.fi-creative-transfer-1:before { content: "\f172"; }
.fi-creative-trash:before { content: "\f173"; }
.fi-creative-trash-1:before { content: "\f174"; }
.fi-creative-umbrella:before { content: "\f175"; }
.fi-creative-upload:before { content: "\f176"; }
.fi-creative-upload-1:before { content: "\f177"; }
.fi-creative-upload-2:before { content: "\f178"; }
.fi-creative-upload-3:before { content: "\f179"; }
.fi-creative-upload-4:before { content: "\f17a"; }
.fi-creative-user:before { content: "\f17b"; }
.fi-creative-user-1:before { content: "\f17c"; }
.fi-creative-user-2:before { content: "\f17d"; }
.fi-creative-user-3:before { content: "\f17e"; }
.fi-creative-users:before { content: "\f17f"; }
.fi-creative-volume:before { content: "\f180"; }
